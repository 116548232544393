import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../global-state';
import Modal from 'react-modal';
import '@fortawesome/fontawesome-free/css/all.css';

//import 'react-modal/style.css';

//import Claim from './claim';


export default function Profile() {
  const [state] = useContext(AppContext);
  //const storedAccessToken = localStorage.getItem('accessToken');
 // const [accessToken, setAccessToken] = useState(null);
  const storedAccessToken = localStorage.getItem('accessToken');
  const [accessToken, setAccessToken] = useState(storedAccessToken);
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updatedCreditCard, setUpdatedCreditCard] = useState('');
  const [updatedCVV, setUpdatedCVV] = useState('');
  const [cvvVisible, setCvvVisible] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  // Example of detecting theme change
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkTheme(mediaQuery.matches);

    const handleChange = (event) => {
      setIsDarkTheme(event.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const email=state.email;
  console.log("Profile Email:",email);
  const toggleCVV = () => {
    setCvvVisible(!cvvVisible);
};

  function hideCreditCardNumber(ccNumber) {
    return "**** **** **** " + ccNumber.slice(-4);
}
function hideCVV(cvv, visible) {
  return visible ? cvv : "***";
}

// function toggleCVV() {
//     var cvvElement = document.getElementById("cvv");
//     if (cvvElement.style.display === "none") {
//         cvvElement.style.display = "inline";
//     } else {
//         cvvElement.style.display = "none";
//     }
// }



 // Event handler for opening the dialog
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  // Event handler for closing the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
 
  const divStyle = {
    margin: '8px 0',
    color: isDarkTheme ? '#FFFFFF' : '#3B2A44', // Adjust color based on theme
  };
  // useEffect(() => {
  //   const fetchAccessToken = async () => {
  //     try {
       
  //       if (!accessToken) {
  //         console.log('Fetching access token..');
  //         const response = await fetch("https://frlab.securdilabs.com:9090/am/oauth2/realms/root/realms/alpha/access_token", {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/x-www-form-urlencoded',
  //           },
  //           body: new URLSearchParams({
  //             'grant_type': 'password',
  //             'username': 'vikingadmin',
  //             'password': 'SecurDI@123',
  //             'scope': 'fr:idm:*',
  //             'client_id': '{{postmanAdminClientId}}',
  //             'client_secret': 'SecurDI@123',
  //           }),
  //           redirect: 'follow',
  //         });

  //         const result = await response.json();
  //         setAccessToken(result.access_token);
  //         localStorage.setItem('accessToken', result.access_token); // Store the token in localStorage
  //         console.log("Access Token:", result.access_token);
  //       }
        
  //     } catch (error) {
  //       console.error('Error fetching access token:', error);
  //     }
  //   };

  //   fetchAccessToken();
  // }, []); // Run this effect only once when the component mounts

  useEffect(() => {
    const fetchData = async () => {
      if (!accessToken) return;
      try {
        console.log("Access Token (inside fetchData):", accessToken);
        const url= `https://fridm.securdilabs.com:8443/openidm/managed/customers_viking?_queryFilter=mail+eq+"${email}"`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
          redirect: 'follow',
        });

        const result = await response.json();
        setUserData(result);
        setLoading(false);
        console.log("JSON Response:", result);
        if (result && result.result && result.result[0] && result.result[0]._id) {
          setUserId(result.result[0]._id);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]); // Run this effect whenever the accessToken changes
  
  if (loading) {
    return <div>Loading...</div>;
  }

  const handleUpdateCreditCard = async () => {
    if (!accessToken) return;    
    try {
      if (!/^\d+$/.test(updatedCreditCard) || !/^\d+$/.test(updatedCVV)) {
        alert('Credit card number and CVV must be numeric');
        console.error('Credit card number and CVV must be numeric');
        return;
      }

      // Check if CVV has exactly 3 digits
      if (updatedCVV.length !== 3) {
        alert('CVV must have exactly 3 digits');
        console.error('CVV must have exactly 3 digits');
        return;
      }
      console.log("Access Token (inside patchData):", accessToken);

      const patchBody = [
        {
          "operation": "replace",
          "field": "/ccnumber",
          "value": updatedCreditCard
        },
        {
          "operation": "replace",
          "field": "/cvv",
          "value": updatedCVV
        },
      ];
      const url = `https://fridm.securdilabs.com:8443/openidm/managed/customers_viking/${userId}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'If-Match': '*',
        },
        redirect: 'follow',
        body: JSON.stringify(patchBody),
      });

      const result = await response.json();
      console.log("JSON Response (Patch Operation):", result);
      // Close the dialog after successful update
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error performing patch operation:', error);
    }
  };


  return (
    <div className={`cstm_container container-fluid ${state.theme.textClass}`}>
    <h1 className={`cstm_head-text text-center ${state.theme.textClass}`}>
      Hey there, {state.username}!
    </h1>
    {userData && (
     <div>
     {/* Display profile picture */}
     {userData.result[0].imageurl && (
       <img
       src={userData.result[0].imageurl}
        alt={`Profile of ${state.username}`}
        style={{
          width: '200px',
          height: '200px',
          objectFit: 'cover',
          borderRadius: '50%',
          margin: '0 auto',
          border: '3px solid #ccc',
          display: 'block',
        }}
       />
     )}
     <div className='user-card'>
    <div className="card-header">
        <h2>User Information</h2>
    </div>
    <div style={divStyle}>
        <p><i className="fas fa-user"></i> <span className="fieldname">{userData.result[0].cn}</span></p>
        <p><i className="fas fa-envelope"></i> <span className="fieldname">{userData.result[0].mail}</span></p>
        <p><i className="fas fa-phone"></i> <span className="fieldname">{userData.result[0].telephoneNumber}</span></p>
        <p><i className="fas fa-globe"></i> <span className="fieldname">{userData.result[0].country}</span></p>
        <p><i className="fas fa-credit-card"></i> <span className="fieldname" id="ccNumber">{hideCreditCardNumber(userData.result[0].ccnumber)}</span></p>
        <p><i className="fas fa-lock"></i> <span className="fieldname" id="cvv">{hideCVV(userData.result[0].cvv, cvvVisible)}</span><i className="fas fa-eye" onClick={toggleCVV}></i></p>
    </div>
    <div className="card-footer">
        <button className="btn btn-primary" onClick={() => setIsDialogOpen(true)}>Update Credit Card Details</button>
    </div>
</div>
          {/* Dialog for updating credit card details */}
          <Modal
            isOpen={isDialogOpen}
            onRequestClose={() => setIsDialogOpen(false)}
            contentLabel="Update Credit Card Details"
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
              },
              content: {
                backgroundColor: '#D2C0DB',
                border: '1px solid #ccc',
                width: '400px',
                height: '250px',
                margin: 'auto',
                borderRadius: '15px',
                padding: '20px',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <h3 style={{ color: '#3B2A44', marginBottom: '20px', fontFamily: 'sans-serif', fontWeight: 'bold'}}>Update Credit Card Details</h3>
            <label style={{ color: '#3B2A44',marginBottom: '10px', fontFamily: 'sans-serif', fontWeight: 'bold' }}>
              New CC Number:
              <input
                style={{ marginLeft: '10px' }}
                type="text"
                value={updatedCreditCard}
                onChange={(e) => setUpdatedCreditCard(e.target.value)}
              />
            </label>
            <label style={{color: '#3B2A44',marginBottom: '10px', fontFamily: 'sans-serif', fontWeight: 'bold'}}>
              CVV:
              <input
                style={{ marginLeft: '10px' }}
                type="text"
                value={updatedCVV}
                onChange={(e) => setUpdatedCVV(e.target.value)}
              />
            </label>
            <div style={{ textAlign: 'center' }}>
              <button className='btn btn-primary' onClick={handleUpdateCreditCard} disabled={!updatedCreditCard || !updatedCVV}>
                Update
              </button>
              <button className='btn btn-primary'
                onClick={() => setIsDialogOpen(false)}
                style={{ marginLeft: '10px', backgroundColor: '#ddd', color: '#333' }}
              >
                Cancel
              </button>
            </div>
          </Modal>
   </div>
    )}
  </div>
   
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../global-state';
import Modal from 'react-modal';

//import userData from './profile';


export default function Claim() {
  const [state] = useContext(AppContext);
  const storedAccessToken = localStorage.getItem('accessToken');
  const [accessToken, setAccessToken] = useState(storedAccessToken);
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updatedDesc, setUpdatedDesc] = useState('');
  const [updatedClaimNum, setUpdatedClaimNum] = useState('');
  const [updatedRenewal, setUpdatedRenewal] = useState('');
  const [updatedDue, setUpdatedDue] = useState('');
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  // Example of detecting theme change
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkTheme(mediaQuery.matches);

    const handleChange = (event) => {
      setIsDarkTheme(event.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);
  const divStyle = {
    margin: '8px 0',
    color: isDarkTheme ? '#FFFFFF' : '#3B2A44', // Adjust color based on theme
  };
  const email=state.email;
  console.log("Claim Email:",email);
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  // Event handler for closing the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
 
  useEffect(() => {
    const fetchData = async () => {
      if (!accessToken) return;

      try {
        console.log("Access Token (inside fetchData):", accessToken);
        const url= `https://fridm.securdilabs.com:8443/openidm/managed/customers_viking?_queryFilter=mail+eq+"${email}"`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
          redirect: 'follow',
        });

        const result = await response.json();
        setUserData(result);
        setLoading(false);
        console.log("JSON Response:", result);
        if (result && result.result && result.result[0] && result.result[0]._id) {
          setUserId(result.result[0]._id);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]); // Run this effect whenever the accessToken changes

  

  if (loading) {
    return <div>Loading...</div>;
  }
 
  const handleUpdatedClaim = async () => {
    if (!accessToken) return;
      console.log("Access Token (inside patchData):", accessToken);
      try{
      const patchBody = [
        {
          "operation": "replace",
          "field": "/claimdescription",
          "value": updatedDesc
        },
        {
          "operation": "replace",
          "field": "/claimnumber",
          "value": updatedClaimNum
        },
        {
          "operation": "replace",
          "field": "/lastRenewalDate",
          "value": updatedRenewal
        },
        {
          "operation": "replace",
          "field": "/dueDate",
          "value": updatedDue
        },
      ];
      const url = `https://fridm.securdilabs.com:8443/openidm/managed/customers_viking/${userId}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'If-Match': '*',
        },
        redirect: 'follow',
        body: JSON.stringify(patchBody),
      });

      const result = await response.json();
      console.log("JSON Response (Patch Operation):", result);
      // Close the dialog after successful update
      setIsDialogOpen(false);
    } 
    catch (error) {
      console.error('Error performing patch operation:', error);
    }
  };

  return (
    <div className={`cstm_container container-fluid ${state.theme.textClass}`}>
    <h1 className={`cstm_head-text text-center ${state.theme.textClass}`}>
      Browse Your claim History here, {state.username}!
    </h1>
    {userData && (
      <div>
     <div className="user-card">
     <div className="card-header">
        <h2>Claim details</h2>
    </div>
    <div style={divStyle}>
     <p>Claim Description: {userData.result[0].claimdescription}</p>
     <p>Claim Number: {userData.result[0].claimnumber}</p>
     <p>Last Renewal Date: {userData.result[0].lastRenewalDate}</p>
     <p>Due Date: {userData.result[0].dueDate}</p>
     </div>
     <div className="card-footer">
        <button className="btn btn-primary" onClick={() => setIsDialogOpen(true)}>Update Claim Details</button>
    </div>
   </div>
   <Modal
            isOpen={isDialogOpen}
            onRequestClose={() => setIsDialogOpen(false)}
            contentLabel="Update Claim Details"
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
              },
              content: {
                backgroundColor: '#D2C0DB',
                border: '1px solid #ccc',
                width: '400px',
                height: '250px',
                margin: 'auto',
                borderRadius: '15px',
                padding: '20px',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <h3 style={{ color: '#3B2A44', marginBottom: '20px', fontFamily: 'sans-serif', fontWeight: 'bold'}}>Update Claim Details</h3>
            <label style={{ color: '#3B2A44',marginBottom: '10px', fontFamily: 'sans-serif', fontWeight: 'bold' }}>
              New Claim Description:
              <input
                style={{ marginLeft: '10px' }}
                type="text"
                value={updatedDesc}
                onChange={(e) => setUpdatedDesc(e.target.value)}
              />
            </label>
            <label style={{color: '#3B2A44',marginBottom: '10px', fontFamily: 'sans-serif', fontWeight: 'bold'}}>
              New Claim Number:
              <input
                style={{ marginLeft: '10px' }}
                type="text"
                value={updatedClaimNum}
                onChange={(e) => setUpdatedClaimNum(e.target.value)}
              />
            </label>
            <label style={{color: '#3B2A44',marginBottom: '10px', fontFamily: 'sans-serif', fontWeight: 'bold'}}>
              Claim Last Renewal Date:
              <input
                style={{ marginLeft: '10px' }}
                type="text"
                value={updatedRenewal}
                onChange={(e) => setUpdatedRenewal(e.target.value)}
              />
            </label>
            <label style={{color: '#3B2A44',marginBottom: '10px', fontFamily: 'sans-serif', fontWeight: 'bold'}}>
              New Claim's Due Date:
              <input
                style={{ marginLeft: '10px' }}
                type="text"
                value={updatedDue}
                onChange={(e) => setUpdatedDue(e.target.value)}
              />
            </label>
            <div style={{ textAlign: 'center' }}>
              <button className='btn btn-primary' onClick={handleUpdatedClaim} disabled={!updatedClaimNum || !updatedDesc || !updatedRenewal || !updatedDue}>
                Update
              </button>
              <button className='btn btn-primary'
                onClick={() => setIsDialogOpen(false)}
                style={{ marginLeft: '10px', backgroundColor: '#ddd', color: '#333' }}
              >
                Cancel
              </button>
            </div>
          </Modal>
   </div>
    )}
  </div>
   
  );
}
